import { Box } from "@boligportal/juice";
import { Ad } from "components/interfaces/ad";
import { t } from "lib/i18n";
import { PopularAds } from "../popular_listings";
import { HomePageHeadline } from "./HomePageHeadline";

interface Props {
  popularListings: Ad[];
}

export const PopularListings = ({ popularListings }: Props) => (
  <Box mt={10}>
    <HomePageHeadline>{t("homepage.Popular ads")}</HomePageHeadline>
    <Box mt={2}>
      <PopularAds listings={popularListings} />
    </Box>
  </Box>
);
