import * as React from "react";
import { Box, styled, Flex, Container } from "@boligportal/juice";

type Props = {
  activeLabelSlot: React.ReactNode;
  itemsSlot: React.ReactNode;
};

export const InteractiveLandlordProductsLayout = ({
  activeLabelSlot,
  itemsSlot,
}: Props) => (
  <BlueBackgroundRelative>
    <Line />
    <ActiveLabel>
      <Flex justify="center">{activeLabelSlot}</Flex>
    </ActiveLabel>
    <Items>
      <Container>
        <Flex justify="space-between">{itemsSlot}</Flex>
      </Container>
    </Items>
  </BlueBackgroundRelative>
);

const BlueBackgroundRelative = styled(Box)`
  position: relative;
  background-color: ${(props) => props.theme.colorPalette.primary[800]};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const Line = styled.div`
  z-index: 1;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: ${(props) => props.theme.colorPalette.primary[500]};
`;

const ActiveLabel = styled.div`
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
`;

const Items = styled.div`
  position: relative;
  z-index: 3;
`;
