import { Box } from "@boligportal/juice";
import { Settings } from "components/interfaces/settings";
import { RentOutBanner } from "./RentOutBanner";
import { InteractiveLandlordProducts } from "./interactive_landlord_products/InteractiveLandlordProducts";

type BlueTopBannerProps = {
  settings: Settings;
  isUserSignedIn: boolean;
};

export const BlueTopBanner = ({
  settings,
  isUserSignedIn,
}: BlueTopBannerProps) => (
  <>
    <Box
      hidden={{
        xs: true,
        lg: false,
      }}
    >
      <InteractiveLandlordProducts
        routes={settings.routes}
        isUserSignedIn={isUserSignedIn}
      />
    </Box>
    <Box
      hidden={{
        xs: false,
        lg: true,
      }}
    >
      <RentOutBanner rentOutUrl={settings.routes.renting_out_landing_page} />
    </Box>
  </>
);
