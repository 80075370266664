import { Flex, styled, Text } from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { TrackingEvent } from "lib/tracking/events";

const Grid = styled.div`
  display: grid;
  grid-gap: ${(props) => props.theme.unit(2)};
  grid-template-columns: 1fr 1fr;

  // The two first cities should be greater in height
  & > :nth-of-type(1),
  & > :nth-of-type(2) {
    min-height: 200px;
  }

  // The cards that are smaller in height should fill entire width
  & > :nth-of-type(n + 3) {
    grid-column: span 2;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    // We only want do show 6 cities on mobile
    && > :nth-of-type(n + 7) {
      display: none;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 75px;

    // Make height of the two first cities same height as 3 rows
    & > :nth-of-type(1),
    & > :nth-of-type(2) {
      grid-row: span 3;
    }

    & > :nth-of-type(n + 3) {
      grid-column: span 1;
    }
  }
`;

const Link = styled.a`
  border: 1px solid #eaeaea;
  overflow: hidden;
  border-radius: ${(props) => props.theme.borderRadius.md};
  display: flex;
  flex-direction: column;
  position: relative;

  &:nth-of-type(n + 3) {
    display: flex;
    flex-direction: row;
    height: 75px;

    span {
      flex-basis: 75px;
      flex-shrink: 0;
      max-width: 75px;
    }
  }

  &:hover {
    text-decoration: none;
  }
`;

const ImageWrapper = styled.span`
  flex: 1;
  overflow: hidden;
  max-height: 300px;
`;

const TileImage = styled(CdnImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Tile = ({
  name,
  href,
  image_url,
}: {
  name: string;
  href: string;
  image_url: string;
}) => {
  if (!name || !href || !image_url) {
    return null;
  }

  return (
    <Link
      href={href}
      onClick={() => TrackingEvent.clickHomePagePopularCity(name)}
    >
      <ImageWrapper>
        <TileImage
          src={image_url}
          alt={name}
        />
      </ImageWrapper>
      <Flex
        bg="base"
        p={2}
        align="center"
        width="100%"
      >
        <Text
          weight="bold"
          size={{
            md: "h5",
            xs: "body",
          }}
        >
          {name}
        </Text>
      </Flex>
    </Link>
  );
};

export const CityTiles = ({
  cities,
}: {
  cities: {
    name: string;
    href: string;
    image_url: string;
  }[];
}) => (
  <Grid>
    {cities.map((city) => (
      <Tile
        key={city.name}
        {...city}
      />
    ))}
  </Grid>
);
