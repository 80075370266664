import {
  Box,
  styled,
  Flex,
  Button,
  IconArrowForward,
  useTheme,
} from "@boligportal/juice";
import { Icon } from "@boligportal/juice/dist/components/Icon/Icon";
import { css } from "@emotion/react";

type Props = {
  text: string;
  icon: typeof Icon;
  url: string;
  index: number;
  onSetActiveItem: (index: number | null) => void;
  trackerFunction: () => void;
};

export const InteractiveLandlordProductItem = ({
  text,
  icon,
  url,
  index,
  onSetActiveItem,
  trackerFunction,
}: Props) => {
  const theme = useTheme();
  const Icon = icon;
  const hideArrow = index === 0;
  const isBranded = index === 0;

  return (
    <Flex
      py={4}
      column
      height="100%"
    >
      <Flex height="50px" />
      <div
        onMouseOver={() => onSetActiveItem(index)}
        onMouseOut={() => onSetActiveItem(null)}
      >
        <Flex
          column
          align="center"
          height="100%"
        >
          <BlueBackground>
            <BlueBackground
              width="16px"
              align="center"
            >
              <IconArrowForward
                css={css`
                  display: ${hideArrow ? "none" : "block"};
                  color: ${theme.colorPalette.primary[500]};
                  font-size: 24px;
                  margin-left: -16px;
                `}
              />
            </BlueBackground>

            <CircleBox
              border
              p={1}
            >
              <Icon
                css={css`
                  color: #ffffff;
                  font-size: 20px;
                `}
              />
            </CircleBox>

            <BlueBackground width="16px" />
          </BlueBackground>
          <Flex
            align="end"
            height="50px"
            mt={1}
          >
            <Button
              onClick={trackerFunction}
              href={url}
              variant={isBranded ? "branded" : "primary"}
            >
              {text}
            </Button>
          </Flex>
        </Flex>
      </div>
    </Flex>
  );
};

const CircleBox = styled(Box)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorPalette.primary[700]};
  border-color: ${({ theme }) => theme.colorPalette.primary[400]};
`;

const BlueBackground = styled(Flex)`
  background-color: ${(props) => props.theme.colorPalette.primary[800]};
`;
