import { Box, Card, Flex, Text } from "@boligportal/juice";
import styled from "@emotion/styled";
import { CardProps } from "./Onboarding";

type OnboardingCardProps = {
  card: CardProps;
};

const Image = styled.img`
  max-width: 104px;
  width: 100%;
  margin-block: 1.5rem;
`;

const BoxOnboarding = styled(Box)`
  display: flex;
  flex: 0 0 auto;
  margin: 0;
  width: 60%;
  flex-grow: 1;
  &:first-child {
    margin-left: 16px;
  }
  &:last-child {
    margin-right: 16px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: 1;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
const CardBoarding = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme }) => theme.borderWidth.sm} solid
    ${({ theme }) => theme.colorPalette.gray[40]};
`;

export const OnboardingCard = ({ card }: OnboardingCardProps) => {
  return (
    <BoxOnboarding onClick={card.click}>
      <CardBoarding>
        <Image
          src={card.image}
          alt={card.alt}
        />
        <Flex column>
          <Text
            color="base"
            weight="headline"
            mb={0.5}
          >
            {card.title}
          </Text>
          <Text
            color="muted"
            mb={3}
            size={"small"}
          >
            {card.description}
          </Text>
        </Flex>

        <Text
          color="branded"
          mt="auto"
        >
          {card.btnText}
        </Text>
      </CardBoarding>
    </BoxOnboarding>
  );
};
