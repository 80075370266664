import * as React from "react";

const SvgDoor42 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        d="M2 36h37M6 35V7h15v28"
      />
      <path d="M28.137 18.9a3 3 0 115.725 0 5 5 0 11-5.725 0z" />
      <path
        strokeLinecap="round"
        d="M31 25v5m-21-5h2"
      />
      <path d="M28 31h6l-1 5h-4zM10 11h7v10h-7z" />
    </g>
  </svg>
);
export default SvgDoor42;
