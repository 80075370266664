import * as React from "react";

const SvgApartment42 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M9 18h4v7H9zM9 7h4v7H9zm0 22h4v7H9zm8-11h4v7h-4zm0-11h4v7h-4zm0 22h4v7h-4z" />
      <path
        strokeLinecap="round"
        d="M2 40h37M4 39V3h22v20m0 10v6"
      />
      <path d="M26.031 23.5a4 4 0 117.938 0 6 6 0 11-7.938 0z" />
      <path
        strokeLinecap="round"
        d="M30 30v9"
      />
    </g>
  </svg>
);
export default SvgApartment42;
