import { Box, Container } from "@boligportal/juice";

type Props = {
  blueTopBanner: React.ReactNode;
  homePageHeader: React.ReactNode;
  onboarding: React.ReactNode;
  categories: React.ReactNode;
  newest: React.ReactNode;
  topFavorites: React.ReactNode;
  favorites: React.ReactNode;
  popularCities: React.ReactNode;
  popularListings: React.ReactNode;
  landlordSection: React.ReactNode;
  customServiceBanner: React.ReactNode;
  popularSearches: React.ReactNode;
  footer: React.ReactNode;
};

export const HomePageLayout = ({
  blueTopBanner,
  homePageHeader,
  onboarding,
  categories,
  newest,
  topFavorites,
  favorites,
  popularCities,
  popularListings,
  landlordSection,
  customServiceBanner,
  popularSearches,
  footer,
}: Props) => (
  <Box bg="tint">
    {homePageHeader}
    {blueTopBanner}
    {onboarding}
    {newest}
    {favorites}
    {topFavorites}
    {categories}
    <Box mt={10}>
      <Container>
        {popularCities}
        {popularListings}
        {landlordSection}
        {customServiceBanner}
        {popularSearches}
      </Container>
    </Box>
    {footer}
  </Box>
);
