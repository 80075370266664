import { Box, Flex, styled, Container, Text } from "@boligportal/juice";
import { App } from "components/app";
import { Header } from "components/header";
import {
  LocationAutoComplete,
  navigateToSuggestion,
} from "components/location_auto_complete/LocationAutoComplete";
import { MobileSearch } from "components/mobile-search/mobile_search";
import { useHiddenState, useSearchHistory } from "hooks/useHistory";
import { t } from "lib/i18n";
import { formatNumber } from "lib/utils";
import { LatestSearchesDropdown } from "./LatestSearchesDropdown";

const StyledHeadline = styled(Text)`
  font-size: ${(props) => `${props.theme.unit(5)}`};
  line-height: 48px;
`;

const StyledHomepageHeader = styled(Flex)`
  background-color: ${(props) => props.theme.colorPalette.brand[500]};
`;

type Props = {
  listingCount: number;
};

export const HomePageHeader = ({ listingCount }: Props) => {
  const { isLatestSearchesHidden, setIsLatestSearchesHidden } =
    useHiddenState();
  const { searchHistory } = useSearchHistory();

  const handleClearHistory = () => {
    setIsLatestSearchesHidden(true);
  };

  return (
    <StyledHomepageHeader
      pb={{
        xs: 8,
        md: 12,
      }}
      column
      justify="center"
      align="center"
    >
      <Header inverseColor />
      <Container fluid>
        <Flex
          column
          justify="center"
          align="center"
          mt={{
            xs: 6,
            md: 10,
          }}
        >
          <Box>
            <StyledHeadline
              color="inverted"
              weight="bold"
            >
              {t("homepage.heading", {
                listing_count: formatNumber(listingCount),
              })}
            </StyledHeadline>
          </Box>
          <Flex
            column
            justify="start"
            maxWidth={"600px"}
            width={"100%"}
            pr={{
              xs: 0,
              md: 2,
            }}
            pl={{
              xs: 0,
              md: 2,
            }}
          >
            <Box
              hidden={{
                xs: false,
                lg: true,
              }}
              mb={3}
              mt={3}
            >
              <MobileSearch
                withSuggestionMap
                displayTriggerAsIcon={false}
                onSelect={navigateToSuggestion}
                latestSearches={
                  !isLatestSearchesHidden &&
                  searchHistory.length !== 0 && (
                    <LatestSearchesDropdown
                      searchHistory={searchHistory}
                      onClear={handleClearHistory}
                    />
                  )
                }
              />
            </Box>
            <Box
              hidden={{
                xs: true,
                lg: false,
              }}
              mt={6}
              mb={3}
            >
              <LocationAutoComplete
                withSuggestionMap
                onSelect={navigateToSuggestion}
                placeholder={t("search_field.placeholder", {
                  search_placeholder_city: App.settings.search_placeholder_city,
                })}
                latestSearches={
                  !isLatestSearchesHidden &&
                  searchHistory.length !== 0 && (
                    <LatestSearchesDropdown
                      searchHistory={searchHistory}
                      onClear={handleClearHistory}
                    />
                  )
                }
              />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </StyledHomepageHeader>
  );
};
