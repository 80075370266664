import * as React from "react";
import { Box, Link, IconArrowForward, styled, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { CdnImage } from "./CdnImage";
import { App } from "./app";

const HouseSvg = ({ className = "" }: { className?: string }) => (
  <svg
    width="150"
    height="120"
    viewBox="0 0 150 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5009 70.9051C0.0616615 84.7773 0.059082 85.4622 0.059082 100.614V177.419C0.059082 182.656 2.52393 185.039 7.98285 185.039H176.685C182.22 185.039 184.603 182.724 184.603 177.419V100.614C184.603 85.4623 184.603 84.7432 170.197 70.9051C165.451 66.3468 144.984 46.6839 108.794 11.9224C106.877 10.0801 104.908 8.18959 102.887 6.24691C99.6826 3.17034 96.7481 0.495728 92.3309 0.495728C87.9138 0.495728 85.0232 3.18439 81.8234 6.24691C77.5668 10.3229 75.5662 12.2315 75.8215 11.9766C41.8401 44.6328 21.3995 64.2774 14.5009 70.9051Z"
      fill="#ED6B2D"
      fillOpacity="0.05"
    />
  </svg>
);

const IMAGE_SIZE = 70;

const ImageWrapper = styled(Box)`
  position: absolute;
  right: ${(props) => props.theme.unit(3)};
  bottom: ${(props) => props.theme.unit(3)};
  width: ${IMAGE_SIZE * 1.5}px;
  height: 100px;
  margin-left: ${(props) => props.theme.unit(2)};
  z-index: 1;
`;

const Image = styled(CdnImage)`
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 100;
`;

const TopLeftImage = styled(Image)`
  position: relative;
`;

const BottomRightImage = styled(Image)`
  position: relative;
  left: 30%;
  bottom: 45%;
`;

const HouseIcon = styled(HouseSvg)`
  position: absolute;
  right: -25px;
  bottom: -25px;
`;

const Wrapper = styled(Box)`
  overflow: hidden;
  position: relative;
`;

type Props = Pick<
  React.ComponentProps<typeof Box>,
  "mt" | "border" | "borderRadius"
>;

export const ContactCustomerSupportBanner = (boxProps: Props) => (
  <Wrapper
    bg="base"
    p={3}
    {...boxProps}
  >
    <Box
      maxWidth={{
        md: 60,
        lg: 80,
      }}
    >
      <Text
        block
        size="h4"
        color="branded"
        weight="bold"
        mb={2}
      >
        {t("contact_customer_support_banner.title")}
      </Text>
      <Text paragraph>{t("contact_customer_support_banner.text")}</Text>

      <Link
        newTab
        underline="hover"
        href={App.settings.support_url}
        color="brand"
      >
        {t("contact_customer_support_banner.cta_text")}
        <IconArrowForward
          ml={1}
          inline
          size="medium"
          color="branded"
        />
      </Link>
    </Box>
    <ImageWrapper
      hidden={{
        md: false,
        xs: true,
      }}
    >
      <TopLeftImage
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
        src="/static/images/customer_support_portrait/portrait_1.png"
        alt="Customer service person"
      />
      <BottomRightImage
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
        src="/static/images/customer_support_portrait/portrait_2.png"
        alt="Customer service person"
      />
      <HouseIcon />
    </ImageWrapper>
  </Wrapper>
);
