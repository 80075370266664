import {
  Box,
  Button,
  Flex,
  IconTimeOutline,
  Link,
  styled,
  Text,
} from "@boligportal/juice";
import { SavedSearchItem } from "hooks/useHistory";
import { t } from "lib/i18n";
import { capitalize } from "lodash-es";

const StyledLink = styled(Link)`
  width: 100%;

  &:hover {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    background: ${(props) => props.theme.color.bg.tint};
  }
`;

const StyledLineLimitedText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

type Props = {
  searchHistory: SavedSearchItem[];
  onClear: () => void;
};

export const LatestSearchesDropdown = ({ searchHistory, onClear }: Props) => {
  const searchHistoryLinks = () =>
    searchHistory.map((item) => (
      <Flex
        flexGrow
        align="center"
        key={item.url}
      >
        <StyledLink
          href={item.url}
          underline="none"
        >
          <Flex
            align="center"
            py={1}
          >
            <Box
              py={1}
              mr={2}
            >
              <IconTimeOutline color="muted" />
            </Box>
            <Flex column>
              <Text
                block
                weight="bold"
                size={{
                  xs: "small",
                  xl: "body",
                }}
              >
                {capitalize(item.city)}
              </Text>
              {item.filters && (
                <StyledLineLimitedText
                  block
                  color="muted"
                  size={{
                    xs: "tiny",
                    xl: "small",
                  }}
                >
                  {item.filters}
                </StyledLineLimitedText>
              )}
            </Flex>
          </Flex>
        </StyledLink>
      </Flex>
    ));

  return (
    <Box m={2.5}>
      <Flex
        justify="space-between"
        mb={1}
      >
        <Text
          color="muted"
          size="small"
          weight="semiBold"
        >
          {t("search_field.latest_searches.title")}
        </Text>
        <Button
          text
          onClick={onClear}
        >
          <Text
            color="muted"
            size="small"
          >
            {t("search_field.latest_searches.clear")}
          </Text>
        </Button>
      </Flex>
      <Box>{searchHistoryLinks()}</Box>
    </Box>
  );
};
