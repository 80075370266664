import * as React from "react";

const SvgCityHouse42Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M21 36V19.212L29.5 12l3.454 3.021v-2.493H36v4.697l2 1.987V36M4 36V16.212L12.5 9l3.454 3.021V9.528H19v4.697l2 1.987V36M2.5 37H40" />
      <path d="M10 34v-8h5v8zm-1 3v-3h7v3zm18-3v-8h5v8zm-1 3v-3h7v3z" />
    </g>
  </svg>
);
export default SvgCityHouse42Px;
