import * as React from "react";

const SvgCabin42Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        d="M2.5 37H39m-20-6h1"
      />
      <path d="M16 37H9a2 2 0 110-4h7m9 0h7a2 2 0 110 4h-7m-9-8H9a2 2 0 110-4h7m9 0h7a2 2 0 110 4h-7" />
      <path
        strokeLinecap="square"
        d="M9 29v4m23-4v4"
      />
      <path
        strokeLinecap="round"
        d="M28 14v-2h4v6"
      />
      <path d="M20.25 12.019L6.414 26.182a2 2 0 11-2.828-2.828L20.232 7.035V7l.018.018.018-.018v.035l16.646 16.319a2 2 0 11-2.829 2.828L20.25 12.019z" />
      <path
        strokeLinecap="square"
        d="M9.5 25H16m9.5 0H32"
      />
      <path
        strokeLinecap="round"
        d="M11.5 21h17M16 17h9m-9 20V25c3-1 6-1 9 0v12h-9z"
      />
    </g>
  </svg>
);
export default SvgCabin42Px;
