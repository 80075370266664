import {
  Box,
  Text,
  IconCheckmark,
  styled,
  Flex,
  Button,
  FlexColumn,
} from "@boligportal/juice";
import { CdnImage } from "components/CdnImage";
import { App } from "components/app";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";

const Wrapper = styled(Flex)`
  background: linear-gradient(105.8deg, #223663 0%, #1f3159 100%);
`;

const IconBackground = styled(Box)`
  background: ${(props) => props.theme.color.text.success};
`;

export const LandlordBanner = () => {
  const sellingPoints = [
    t("homepage.landlord_banner.selling_point_1"),
    t("homepage.landlord_banner.selling_point_2"),
    t("homepage.landlord_banner.selling_point_3"),
  ];

  return (
    <Wrapper
      mt={7}
      column={{
        lg: false,
        xs: true,
      }}
      align="center"
      borderRadius="sm"
      py={{
        xs: 3,
        md: 5,
        lg: 8,
      }}
      px={{
        xs: 2,
        md: 4,
      }}
    >
      <FlexColumn
        size={{
          xs: 12,
          lg: 5,
        }}
      >
        <Box
          pr={3}
          pl={{
            xs: 3,
            lg: 0,
          }}
        >
          <CdnImage
            width="100%"
            src={
              App.settings.market === Market.BOSTADSPORTAL_SE
                ? "/static/images/home_page/bostadsportal.se/ad-setup.png"
                : "/static/images/home_page/boligportal.dk/ad-setup.png"
            }
            alt="Preview of an ad page"
          />
        </Box>
      </FlexColumn>
      <FlexColumn
        size={{
          xs: 12,
          lg: 7,
        }}
      >
        <Text
          block
          color="inverted"
          weight="bold"
          size="h2"
          mt={{
            xs: 2,
            lg: 0,
          }}
        >
          {t("homepage.landlord_banner.title")}
        </Text>
        <Text
          block
          color="inverted"
          mb={{
            xs: 2,
            lg: 3,
          }}
        >
          {t("homepage.landlord_banner.text", {
            brand_name: App.settings.brand_name,
          })}
        </Text>
        <Box
          mb={{
            xs: 2,
            md: 3,
          }}
        >
          {sellingPoints.map((text) => (
            <Flex
              key={text}
              align="center"
              my={1.5}
            >
              <IconBackground
                borderRadius="full"
                bg="tint"
                inline
                p={0.5}
              >
                <IconCheckmark color="inverted" />
              </IconBackground>
              <Text
                ml={1.5}
                color="inverted"
              >
                {text}
              </Text>
            </Flex>
          ))}
        </Box>
        <Button
          href={App.settings.routes.renting_out_landing_page}
          fullWidth={{
            xs: true,
            lg: false,
          }}
          variant="branded"
          onClick={
            TrackingEvent.clickHomePageRentOutFrontPageOrangeBottomBanner
          }
        >
          {t("homepage.landlord_banner.cta_button_text")}
        </Button>
      </FlexColumn>
    </Wrapper>
  );
};
