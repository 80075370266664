import { t } from "lib/i18n";
import { CityTiles } from "../city_tiles";
import { HomePageHeadline } from "./HomePageHeadline";

interface Props {
  popularCities: Array<{
    name: string;
    href: string;
    image_url: string;
  }>;
}

export const Cities = ({ popularCities }: Props) => (
  <>
    <HomePageHeadline>{t("homepage.Popular cities")}</HomePageHeadline>
    <CityTiles cities={popularCities} />
  </>
);
