import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ContactCustomerSupportBanner } from "components/ContactCustomerSupportBanner";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { Footer } from "components/footer";
import { Ad } from "components/interfaces/ad";
import { Anchor } from "components/interfaces/anchor_link";
import { Link } from "components/interfaces/links";
import { Plan } from "components/interfaces/plan";
import { AuthModal, AuthView } from "components/modals/auth_modal";
import useAuthActions from "components/modals/useAuthActions";
import { SignupContext } from "lib/enums";
import { Categories } from "./components/Categories";
import { Cities } from "./components/Cities";
import { Favorites } from "./components/Favorites";
import { HomePageHeader } from "./components/HomePageHeader";
import { LandlordBanner } from "./components/LandlordBanner";
import { Newest } from "./components/Newest";
import { Onboarding } from "./components/Onboarding";
import { PopularListings } from "./components/PopularListings";
import { PopularSearches } from "./components/PopularSearches";
import { TopFavorites } from "./components/TopFavorites";
import { BlueTopBanner } from "./components/blue_top_banner";
import { HomePageLayout } from "./components/layout/HomePageLayout";

interface Props {
  listings_count: number;
  city_area_anchors: Anchor[];
  category_anchors: { id: string; href: string; text: string }[];
  popular_cities: Array<{
    name: string;
    href: string;
    image_url: string;
  }>;
  popular_ads: Ad[];
  popular_searches: Link[];
  student_housing_links: Anchor[];
  seeker_plans: Plan[];
}

export default function HomePageApp({
  listings_count,
  city_area_anchors,
  category_anchors,
  popular_cities,
  popular_ads,
  popular_searches,
  student_housing_links,
}: Props) {
  const { settings } = App;
  const { modalActionsInstance } = useAuthActions({});
  const history = useHistory();
  const { user } = useUser();

  const useQuery = () => new URLSearchParams(history.location.search);
  const query = useQuery();

  const getLoginContext = () => {
    const context = query.get("context");

    if (context?.includes("newsletter")) {
      return SignupContext.NEWSLETTER;
    }

    if (context?.includes("promote")) {
      return SignupContext.PROMOTE;
    }

    if (context?.includes("create")) {
      return SignupContext.CREATE_RENTABLE;
    }

    if (context?.includes("contracts")) {
      return SignupContext.CONTRACTS;
    }

    if (context?.includes("moving-report")) {
      return SignupContext.MOVING_REPORTS;
    }

    if (context?.includes("rent")) {
      return SignupContext.CREATE_RENT_COLLECTION;
    }

    // eslint-disable-next-line no-useless-return
    return;
  };

  useEffect(() => {
    const targetUrl = query.get("next");
    if (history.location.pathname.includes("login") && targetUrl && !user) {
      modalActionsInstance.showModal(
        <AuthModal
          view={AuthView.LOGIN}
          context={getLoginContext()}
          targetUrl={targetUrl}
        />,
      );
    }
  }, []);

  return (
    <>
      <HomePageLayout
        homePageHeader={<HomePageHeader listingCount={listings_count} />}
        blueTopBanner={
          !user ||
          (user?.has_created_rentable && !user?.has_premium_seeker_access) ? (
            <BlueTopBanner
              settings={settings}
              isUserSignedIn={user !== null}
            />
          ) : null
        }
        onboarding={
          (user && !user.has_created_rentable) ||
          (user && user?.has_premium_seeker_access) ? (
            <Onboarding />
          ) : null
        }
        categories={<Categories categoryAnchors={category_anchors} />}
        newest={<Newest />}
        topFavorites={<TopFavorites />}
        favorites={<Favorites />}
        popularCities={<Cities popularCities={popular_cities} />}
        landlordSection={<LandlordBanner />}
        customServiceBanner={
          <ContactCustomerSupportBanner
            border
            borderRadius="sm"
            mt={{
              xs: 6,
              md: 8,
            }}
          />
        }
        popularListings={<PopularListings popularListings={popular_ads} />}
        popularSearches={
          <PopularSearches
            popularAreas={city_area_anchors}
            popularSearches={popular_searches}
            studentHousingLinks={student_housing_links}
          />
        }
        footer={<Footer />}
      />
    </>
  );
}
