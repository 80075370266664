import { useEffect, useState } from "react";
import { Box, Container, Headline, styled } from "@boligportal/juice";
import { useGetFavourites } from "api/queries";
import { App } from "components/app";
import { Profile } from "components/interfaces/profile";
import { Market } from "components/markets/market_settings";
import { API } from "lib/api";
import { FavoriteCardStatus, OrderType } from "lib/enums";
import { t } from "lib/i18n";
import { awaitTimeout } from "lib/timeout";
import { TrackingEvent } from "lib/tracking/events";
import { OnboardingCard } from "./OnboardingCard";

export type CardProps = {
  image?: string;
  title: string;
  alt: string;
  description: string;
  btnText: string;
  fulfilled: boolean | undefined;
  click: () => void;
};

type OnboardingCards = {
  [key: string]: CardProps;
};

const ScrollingWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 1rem;
  width: 100%;
  min-height: 350px;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 540px;
    margin-inline: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 720px;
    padding-inline: 16px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: 1140px;
  }
`;

/**
 * This component renders Cards that reminds the user that some features hasn't been used,
 * such as adding a favorite, adding an agent or entering profile infos.
 *
 */

export const Onboarding = () => {
  const [userHasAgents, setUserHasAgents] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [isLoadingAgents, setIsLoadingAgents] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  useEffect(() => {
    setIsLoadingAgents(true);
    API.getAgents().then((agents) => {
      const hasAgent = agents.length > 0;
      setUserHasAgents(hasAgent);
    });
    setIsLoadingAgents(false);
  }, []);

  useEffect(() => {
    setIsLoadingProfile(true);
    API.getProfile().then((profile) => {
      setUserProfile(profile.profile);
    });
    setIsLoadingProfile(false);
  }, []);

  const { data, isLoading: isLoadingFavourites } = useGetFavourites({
    offset: 0,
    limit: 1,
    status: FavoriteCardStatus.ALL,
    sorting: OrderType.DEFAULT,
  });

  const hasFavourites = data && data.ads.length > 0;

  const isProfileFulfilled = checkProfileFulfilled(
    filterProfileKeys(userProfile),
  );

  const onBoardingCards: OnboardingCards[] = [
    {
      agent: {
        image: "/static/images/onboarding/agent.svg",
        alt: t("homepage.onboarding.agent.alt"),
        title: t("homepage.onboarding.agent.title"),
        description: t("homepage.onboarding.agent.description"),
        btnText: t("homepage.onboarding.agent.btnText"),
        fulfilled: userHasAgents,
        click: () => navigateToAgent(),
      },
      favourites: {
        image: "/static/images/onboarding/favorites_2.svg",
        alt: t("homepage.onboarding.favourites.alt"),
        title: t("homepage.onboarding.favourites.title"),
        description: t("homepage.onboarding.favourites.description"),
        btnText: t("homepage.onboarding.favourites.btnText"),
        fulfilled: hasFavourites,
        click: () => navigateToFavorite(),
      },
      profile: {
        image: "/static/images/onboarding/profile.svg",
        alt: t("homepage.onboarding.profile.alt"),
        title: t("homepage.onboarding.profile.title"),
        description: t("homepage.onboarding.profile.description"),
        btnText: t("homepage.onboarding.profile.btnText"),
        fulfilled: isProfileFulfilled,
        click: () => navigateToProfile(),
      },
      benefits: {
        image: "/static/images/onboarding/benefits.svg",
        alt: t("homepage.onboarding.benefits.alt"),
        title: t("homepage.onboarding.benefits.title"),
        description: t("homepage.onboarding.benefits.description"),
        btnText: t("homepage.onboarding.benefits.btnText"),
        fulfilled: false,
        click: () => navigateToBenefits(),
      },
    },
  ];

  const appMarket = App.settings.market;

  function checkSwedishMarketNoBenefits(
    cards: { [key: string]: CardProps }[],
    market: string,
  ) {
    const arrayCards: CardProps[] = [];
    for (const card of cards) {
      for (const key in card) {
        if (market === Market.BOSTADSPORTAL_SE) {
          if (key === "benefits") {
            continue;
          } else {
            const innerCard: CardProps = card[key];
            arrayCards.push(innerCard);
          }
        } else {
          const innerCard: CardProps = card[key];
          arrayCards.push(innerCard);
        }
      }
    }
    return arrayCards;
  }

  const onBoardingCardsMarketChecked = checkSwedishMarketNoBenefits(
    onBoardingCards,
    appMarket,
  );

  const hasUnfulfilledCards = onBoardingCardsMarketChecked.filter((card) => {
    return !card.fulfilled;
  });

  /**
   * Tracking events for the onboarding cards.
   * We need to wait for the tracking event to be sent before redirecting the user to the next page.
   * That's why we use the awaitTimeout function to wait for 500ms before redirecting.
   */

  const navigateToAgent = async () => {
    TrackingEvent.clickHomePageOnboardingAgent();

    await awaitTimeout(500);

    window.location.href = App.settings.routes.search_result_page;
  };
  const navigateToFavorite = async () => {
    TrackingEvent.clickHomePageOnboardingFavorites();

    await awaitTimeout(500);

    window.location.href = App.settings.routes.search_result_page;
  };
  const navigateToProfile = async () => {
    TrackingEvent.clickHomePageOnboardingProfile();

    await awaitTimeout(500);

    window.location.href = App.settings.routes.profile;
  };
  const navigateToBenefits = async () => {
    TrackingEvent.clickHomePageOnboardingBenefits();

    await awaitTimeout(500);

    window.location.href = App.settings.routes.benefits;
  };

  function filterProfileKeys(obj: { [key: string]: Profile }) {
    const selectedKeys = [
      "birthday",
      "expat",
      "kids",
      "pets",
      "smoking",
      "share_apartment",
      "occupation",
      "user_type",
      "user_gender",
      "profile_image",
    ];
    const filteredObj: { [key: string]: Profile } = {};
    for (const key in obj) {
      if (selectedKeys.includes(key)) {
        filteredObj[key] = obj[key];
      }
    }
    return filteredObj;
  }

  function checkProfileFulfilled(obj: { [key: string]: Profile }) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] === null) {
          return false;
        }
      }
    }
    return true;
  }

  if (isLoadingAgents || isLoadingProfile || isLoadingFavourites) {
    return null;
  }

  if (!data || !userProfile) {
    return null;
  }

  return hasUnfulfilledCards.length > 1 ? (
    <Box mt={10}>
      <Container>
        <Headline as="h3">
          {t("homepage.onboarding.headline.features")}
        </Headline>
      </Container>
      <ScrollingWrapper>
        {hasUnfulfilledCards.map((card, index) => (
          <OnboardingCard
            card={card}
            key={index}
          />
        ))}
      </ScrollingWrapper>
    </Box>
  ) : null;
};
