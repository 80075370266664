import * as React from "react";

const SvgApartmentHouse42Px = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path d="M9 18h4v7H9zM9 7h4v7H9zm0 22h4v7H9zm8-11h4v7h-4zm0-11h4v7h-4z" />
      <path
        strokeLinecap="round"
        d="M2 40h37M4 39V3h22v20"
      />
      <path
        strokeLinecap="round"
        d="M22 33v-3h-3l10-10 4 4v-2h3v5l3 3h-3v10h0-14"
      />
      <path d="M20 40a3 3 0 111.365-5.672 4 4 0 017.122-.289A3 3 0 0128 40h-8z" />
    </g>
  </svg>
);
export default SvgApartmentHouse42Px;
