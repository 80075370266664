import { Box, Container, styled, Text } from "@boligportal/juice";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import SvgApartment42px from "media/icons/Apartment42";
import SvgApartmentHouse42px from "media/icons/ApartmentHouse42px";
import SvgCabin42px from "media/icons/Cabin42px";
import SvgCityHouse42Px from "media/icons/CityHouse42Px";
import SvgDoor42px from "media/icons/Door42";
import SvgHouse42px from "media/icons/House42";
import { HomePageHeadline } from "./HomePageHeadline";

const StyledRentalCategoryLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 70px;
  grid-gap: 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const StyledRentalCategoryLinksItem = styled.a<{ fullWidth: boolean }>`
  background-color: white;
  -webkit-appearance: none;
  overflow: hidden;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  font-weight: ${(props) => props.theme.fontWeight.semiBold};
  padding: ${(props) => props.theme.unit(2)};
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid ${(props) => props.theme.color.border};

  ${(props) =>
    props.fullWidth &&
    `
    grid-column: span 2;
  `}

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-column: span 1;
  }

  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${(props) => props.theme.color.text.base};
  }

  svg {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    white-space: nowrap;
    font-weight: 500;
    width: 30px;
    height: 30px;
    margin-right: ${(props) => props.theme.unit(1.5)};
    box-sizing: border-box;
    color: #181d1b;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export const Categories = (props: {
  categoryAnchors: { id: string; href: string; text: string }[];
}) => {
  const { categoryAnchors } = props;

  return (
    <Box mt={10}>
      <Container>
        <HomePageHeadline>{t("homepage.Categories")}</HomePageHeadline>

        <StyledRentalCategoryLinks>
          {categoryAnchors.reduce<JSX.Element[]>((accumulator, link) => {
            let Icon;
            let index = 0;
            switch (link.id) {
              case "rental_apartment":
                Icon = SvgApartment42px;
                index = 0;
                break;
              case "rental_room":
                Icon = SvgDoor42px;
                index = 1;
                break;
              case "rental_house":
                Icon = SvgHouse42px;
                index = 2;
                break;
              case "rental_cabin":
                Icon = SvgCabin42px;
                index = 3;
                break;
              case "rental_townhouse":
                Icon = SvgCityHouse42Px;
                index = 4;
                break;
              case "rental_any":
                Icon = SvgApartmentHouse42px;
                index = 5;
                break;
            }

            accumulator[index] = (
              <StyledRentalCategoryLinksItem
                key={link.text}
                href={link.href}
                fullWidth={link.id === "rental_any"}
                onClick={() => TrackingEvent.clickHomePageCategory(link.text)}
              >
                <Icon />
                <Text
                  weight="bold"
                  size={{
                    md: "h5",
                    xs: "body",
                  }}
                >
                  {link.text}
                </Text>
              </StyledRentalCategoryLinksItem>
            );
            return accumulator;
          }, [])}
        </StyledRentalCategoryLinks>
      </Container>
    </Box>
  );
};
