import { Flex, Box, Container, Button, Text, styled } from "@boligportal/juice";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";

const StyledRentOutBannerWrapper = styled(Box)`
  background-color: ${(props) => props.theme.colorPalette.primary[800]};
  color: ${(props) => props.theme.color.text.inverted};
`;

type Props = {
  rentOutUrl: string;
};

export const RentOutBanner = ({ rentOutUrl }: Props) => (
  <StyledRentOutBannerWrapper>
    <Container fluid>
      <Text
        size={{
          xs: "body",
          md: "h4",
          lg: "h3",
        }}
        color="inverted"
      >
        <Flex
          justify={{
            xs: "start",
            md: "center",
          }}
          align="center"
          py={2}
          gap
        >
          <Box>{t("homepage.rentout_headline")}</Box>
          <Box>
            <Button
              variant="branded"
              as={"a"}
              href={rentOutUrl}
              onClick={() =>
                TrackingEvent.clickHomePageRentOutFrontPageBlueTopBanner()
              }
            >
              {t("homepage.rentout_cta")}
            </Button>
          </Box>
        </Flex>
      </Text>
    </Container>
  </StyledRentOutBannerWrapper>
);
