import * as React from "react";

const SvgHouse42 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 42"
    className="Icon"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        d="M6 37V22H2L19 5l7 7v-2h5v7l5 5h-4v7"
      />
      <path
        strokeLinecap="round"
        d="M11 37V22h9v15zm-8.5 0H39"
      />
      <path d="M27 37a3 3 0 111.365-5.672 4 4 0 017.122-.289A3 3 0 0135 37h-8z" />
      <path
        strokeLinecap="round"
        d="M14 30h1"
      />
    </g>
  </svg>
);
export default SvgHouse42;
