import {
  Box,
  Container,
  Flex,
  FlexColumn,
  Hidden,
  Spinner,
} from "@boligportal/juice";
import { useNewestAds } from "api/queries";
import { AdCard } from "components/cards/ad_card";
import { useSearchHistory } from "hooks/useHistory";
import { t } from "lib/i18n";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";
import { capitalize } from "lodash-es";
import { HomePageHeadline } from "./HomePageHeadline";
import { LatestSearches } from "./LatestSearches";

const MIN_AMOUNT = 3;

export const Newest = () => {
  const { searchHistory } = useSearchHistory();
  const city = searchHistory[0] ? searchHistory[0].city : "";

  const { data, isFetching } = useNewestAds(city);

  if (!data) {
    return null;
  }

  if (data.length < MIN_AMOUNT) {
    return null;
  }

  const capitalizedCity = capitalize(city);

  return (
    <Box mt={10}>
      <Container>
        <Hidden
          on={{
            xs: false,
            lg: true,
          }}
        >
          <Box mb={6}>
            <LatestSearches />
          </Box>
        </Hidden>
        <Flex align="end">
          <Box width="100%">
            <HomePageHeadline>
              {t("homepage.newest_ads.title", {
                city: capitalizedCity,
              })}
              {isFetching && (
                <Box ml={2}>
                  <Spinner size="small" />
                </Box>
              )}
            </HomePageHeadline>

            <Flex
              wrap
              gap
            >
              {data.map((item) => (
                <FlexColumn
                  key={item.id}
                  size={{
                    xs: 12,
                    md: 6,
                    lg: 3,
                  }}
                >
                  <AdCard
                    ad={item}
                    placement={FavouriteAdButtonPlacementTracking.home_page}
                  />
                </FlexColumn>
              ))}
              <Hidden
                on={{
                  xs: true,
                  lg: false,
                }}
              >
                <FlexColumn
                  size={{
                    xs: 12,
                    md: 3,
                  }}
                >
                  <LatestSearches />
                </FlexColumn>
              </Hidden>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
