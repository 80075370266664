import { Box, Flex, Link, styled, Text } from "@boligportal/juice";
import { Anchor } from "components/interfaces/anchor_link";
import { t } from "lib/i18n";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-rows: auto;
  column-gap: ${(props) => props.theme.unit(1.5)};
`;

const Title = styled(Text)`
  grid-column: 1 / -1;
  margin-bottom: ${(props) => props.theme.unit(1)};
  color: ${(props) => props.theme.color.text.muted};
  color: ${(props) => props.theme.fontSize.small};
  display: block;
`;

const StyledLink = styled(Link)`
  margin-bottom: ${(props) => props.theme.unit(1.25)};
  display: block;
  font-size: ${(props) => props.theme.fontSize.small};
`;

const SearchLink = ({ href, children }: { href: string; children: string }) => (
  <StyledLink
    underline="hover"
    href={href}
    color="primary"
  >
    {children}
  </StyledLink>
);

const Wrapper = styled(Flex)`
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row-reverse;
  }
`;

type Props = {
  popularAreas: Anchor[];
  popularSearches: Anchor[];
  studentHousingLinks: Anchor[];
};

export const PopularSearches = ({
  popularAreas,
  popularSearches,
  studentHousingLinks,
}: Props) => (
  <Wrapper
    my={6}
    align={{
      lg: "start",
    }}
  >
    <Flex
      gap={4}
      mb={2}
    >
      <Box>
        <Title>{t("homepage.student_housing")}</Title>
        {studentHousingLinks.map(({ href, text }) => (
          <SearchLink
            key={text}
            href={href}
          >
            {text}
          </SearchLink>
        ))}
      </Box>

      <Box>
        <Title>{t("homepage.Popular searches")}</Title>
        {popularSearches.map((link) => (
          <SearchLink
            key={link.href}
            href={link.href}
          >
            {link.text}
          </SearchLink>
        ))}
      </Box>
    </Flex>

    <Grid flexGrow>
      <Title>{t("homepage.Popular areas")}</Title>
      {popularAreas.map((area) => (
        <SearchLink
          key={area.href}
          href={area.href}
        >
          {area.text}
        </SearchLink>
      ))}
    </Grid>
  </Wrapper>
);
