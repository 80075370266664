import { Box, Container, Flex, FlexColumn, Spinner } from "@boligportal/juice";
import { useGetTopFavorites } from "api/queries";
import { useUser } from "components/Providers/UserProvider";
import { AdCard } from "components/cards/ad_card";
import { useSearchHistory } from "hooks/useHistory";
import { t } from "lib/i18n";
import { FavouriteAdButtonPlacementTracking } from "lib/tracking/events";
import { capitalize } from "lodash-es";
import { HomePageHeadline } from "./HomePageHeadline";

const MIN_AMOUNT = 4;

export const TopFavorites = () => {
  const { searchHistory } = useSearchHistory();
  const city = searchHistory[0] ? searchHistory[0].city : "";

  const { data, isFetching } = useGetTopFavorites(city);
  const { user } = useUser();

  if (!data || !data.ads || !user) {
    return null;
  }

  if (data.ads.length < MIN_AMOUNT) {
    return null;
  }

  const capitalizedCity = capitalize(city);

  return (
    <Box mt={10}>
      <Container>
        <HomePageHeadline>
          {t("homepage.top_favorites", {
            city: capitalizedCity,
          })}
          {isFetching && (
            <Box ml={2}>
              <Spinner size="small" />
            </Box>
          )}
        </HomePageHeadline>

        <Flex
          wrap
          gap
        >
          {data.ads.map((item) => (
            <FlexColumn
              key={item.id}
              size={{
                xs: 12,
                md: 6,
                lg: 3,
              }}
            >
              <AdCard
                ad={item}
                placement={FavouriteAdButtonPlacementTracking.home_page}
                isFavouriteDisabled
              />
            </FlexColumn>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};
