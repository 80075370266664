import { Box, Flex, IconHome, Link, styled, Text } from "@boligportal/juice";
import { SavedSearchItem, useSearchHistory } from "hooks/useHistory";
import { capitalize } from "lodash-es";

type Props = {
  item: SavedSearchItem;
};

const StyledLineLimitedText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const SearchHistoryCard = ({ item }: Props) => (
  <Flex
    border
    height={{
      xs: "auto",
      md: "calc(25% - 8px)",
    }}
    mb={{
      xs: 1,
      lg: 0,
    }}
    bg="base"
    align="center"
    borderRadius="sm"
  >
    <StyledLink
      href={item.url}
      underline="none"
    >
      <Flex
        align="center"
        py={1}
        px={2}
      >
        <Box
          p={1}
          mr={2}
          bg="brand"
          borderRadius="md"
        >
          <IconHome
            color="inverted"
            size="large"
          />
        </Box>
        <Flex column>
          <Text
            block
            weight="bold"
            size={{
              xs: "small",
              xl: "body",
            }}
          >
            {capitalize(item.city)}
          </Text>
          {item.filters && (
            <StyledLineLimitedText
              block
              color="muted"
              size={{
                xs: "tiny",
                xl: "small",
              }}
            >
              {item.filters}
            </StyledLineLimitedText>
          )}
        </Flex>
      </Flex>
    </StyledLink>
  </Flex>
);

export const LatestSearches = () => {
  const { searchHistory } = useSearchHistory();

  if (searchHistory.length === 0) {
    return null;
  }

  return (
    <Box height="100%">
      <Flex
        wrap
        column
        gap
        height="100%"
        justify="space-between"
      >
        {searchHistory.map((item) => (
          <SearchHistoryCard
            key={item.name}
            item={item}
          />
        ))}
      </Flex>
    </Box>
  );
};
