import { Text } from "@boligportal/juice";

export const HomePageHeadline = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Text
    block
    color="base"
    size="h3"
    weight="bold"
    mb={2}
  >
    {children}
  </Text>
);
