import {
  Box,
  Button,
  Container,
  Flex,
  FlexColumn,
  IconArrowForward,
  Spinner,
} from "@boligportal/juice";
import { useGetFavourites } from "api/queries";
import { useUser } from "components/Providers/UserProvider";
import { App } from "components/app";
import { AdCard } from "components/cards/ad_card";
import { OrderType, FavoriteCardStatus } from "lib/enums";
import { t } from "lib/i18n";
import { awaitTimeout } from "lib/timeout";
import {
  FavouriteAdButtonPlacementTracking,
  TrackingEvent,
} from "lib/tracking/events";
import { sampleSize } from "lodash-es";
import { HomePageHeadline } from "./HomePageHeadline";

const PAGE_SIZE = 100;
const MIN_AMOUNT = 4;

export const Favorites = () => {
  const { data, isFetching } = useGetFavourites({
    offset: 0,
    limit: PAGE_SIZE,
    status: FavoriteCardStatus.AVAILABLE,
    sorting: OrderType.DEFAULT,
  });
  const { user } = useUser();

  if (!data || !data.ads || !user) {
    return null;
  }

  if (data.ads.length < MIN_AMOUNT) {
    return null;
  }

  const randomFavorites = sampleSize(data.ads, MIN_AMOUNT);

  const navigateToFavouriteAds = async () => {
    TrackingEvent.clickHomePageSeeAllFavorites();

    await awaitTimeout(500);

    window.location.href = App.settings.routes.my_favourite_ads;
  };

  return (
    <Box mt={10}>
      <Container>
        <HomePageHeadline>
          <Flex
            justify="space-between"
            align="baseline"
          >
            <Flex>
              {t("homepage.favorites")}
              {isFetching && (
                <Box ml={2}>
                  <Spinner size="small" />
                </Box>
              )}
            </Flex>

            <Button
              text
              size="small"
              iconAfter={IconArrowForward}
              onClick={navigateToFavouriteAds}
            >
              {t("homepage.favorites.all")}
            </Button>
          </Flex>
        </HomePageHeadline>

        <Flex
          wrap
          gap
        >
          {randomFavorites.map((item) => (
            <FlexColumn
              key={item.id}
              size={{
                xs: 12,
                md: 6,
                lg: 3,
              }}
            >
              <AdCard
                ad={item}
                placement={FavouriteAdButtonPlacementTracking.home_page}
              />
            </FlexColumn>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};
