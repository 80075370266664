import { Box, Flex, FlexColumn, styled } from "@boligportal/juice";
import { AdCard } from "components/cards/ad_card";
import { Ad } from "components/interfaces/ad";
import {
  FavouriteAdButtonPlacementTracking,
  TrackingEvent,
} from "lib/tracking/events";

const AdCardEntry = styled(FlexColumn)`
  // We only want to display 8 ads on mobile
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    &:nth-child(n + 9) {
      display: none;
    }
  }
  margin-top: 8px;
`;

export const PopularAds = ({ listings }: { listings: Ad[] }) => (
  <Flex
    wrap
    gap
  >
    {listings.map((listing) => (
      <AdCardEntry
        key={listing.id}
        size={{
          xs: 12,
          md: 6,
          lg: 3,
        }}
      >
        <Box
          mb={{
            xs: 0,
            lg: 2,
          }}
        >
          <AdCard
            onClick={TrackingEvent.clickHomePagePopularAd}
            ad={listing}
            placement={FavouriteAdButtonPlacementTracking.home_page}
          />
        </Box>
      </AdCardEntry>
    ))}
  </Flex>
);
