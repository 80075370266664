import { useState } from "react";
import {
  IconHome,
  IconArrowBackForward,
  IconServerOutline,
  IconDocumentTextOutline,
  Text,
} from "@boligportal/juice";
import { App } from "components/app";
import { Settings } from "components/interfaces/settings";
import { Market } from "components/markets/market_settings";
import { t } from "lib/i18n";
import { TrackingEvent } from "lib/tracking/events";
import { menuTranslations } from "lib/translations";
import { InteractiveLandlordProductItem } from "./InteractiveLandlordProductsItem";
import { InteractiveLandlordProductsLayout } from "./InteractiveLandlordProductsLayout";

type Props = {
  routes: Settings["routes"];
  isUserSignedIn: boolean;
};

export const InteractiveLandlordProducts = ({
  routes,
  isUserSignedIn,
}: Props) => {
  const isDK = App.settings.market === Market.BOLIGPORTAL_DK;
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const {
    CREATE_LISTING_NEW_MENU,
    CREATE_CONTRACT,
    CREATE_MOVING_REPORT,
    CREATE_RENT,
  } = menuTranslations();

  const items = [
    {
      icon: IconHome,
      url: isUserSignedIn
        ? routes.property_owner.create_listing
        : `/login?next=${routes.property_owner.create_listing}&context=create`,
      text: CREATE_LISTING_NEW_MENU,
      trackerFunction: TrackingEvent.clickHomePageRentOutFrontPageBlueTopBanner,
    },
    {
      icon: IconDocumentTextOutline,
      url: isUserSignedIn
        ? `${routes.contracts}choose-rentable`
        : `/login?next=${routes.contracts}choose-rentable/&context=contracts`,
      text: CREATE_CONTRACT,
      trackerFunction:
        TrackingEvent.clickHomePageContractFrontPageBlueTopBanner,
    },
    {
      icon: IconArrowBackForward,
      url: isUserSignedIn
        ? `${routes.moving_reports}choose-rentable`
        : `/login?next=${routes.moving_reports}choose-rentable/&context=moving-reports`,
      text: CREATE_MOVING_REPORT,
      trackerFunction:
        TrackingEvent.clickHomePageMovingInReportFrontPageBlueTopBanner,
    },
  ];

  if (isDK) {
    items.push({
      icon: IconServerOutline,
      url: isUserSignedIn
        ? `${routes.property_owner.rent}create`
        : `/login?next=${routes.property_owner.rent}create/&context=rent`,
      text: CREATE_RENT,
      trackerFunction:
        TrackingEvent.clickHomePageRentCollectionFrontPageBlueTopBanner,
    });
  }

  const inactiveLabel = t(
    "homepage.blue_top_banner.interactive_landlord_products.inactive_label",
  );
  const activeItemLabels = [
    t("homepage.blue_top_banner.interactive_landlord_products.listing_label"),
    t("homepage.blue_top_banner.interactive_landlord_products.contract_label"),
    t(
      "homepage.blue_top_banner.interactive_landlord_products.moving_report_label",
    ),
    t("homepage.blue_top_banner.interactive_landlord_products.rent_label"),
  ];

  return (
    <InteractiveLandlordProductsLayout
      activeLabelSlot={
        <Text
          mt={3}
          size="h3"
          weight="bold"
          color="inverted"
        >
          {activeItem !== null ? activeItemLabels[activeItem] : inactiveLabel}
        </Text>
      }
      itemsSlot={
        <>
          {items.map((item, index) => (
            <InteractiveLandlordProductItem
              key={item.text}
              url={item.url}
              index={index}
              icon={item.icon}
              text={item.text}
              onSetActiveItem={setActiveItem}
              trackerFunction={item.trackerFunction}
            />
          ))}
        </>
      }
    />
  );
};
